// NavBar.tsx
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AppBar,
    Toolbar,
    IconButton,
    Button,
    Typography,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Login } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import UserMenu from './UserMenu';
import LoginDialog from './LoginDialog';
import LogoutConfirmDialog from './LogoutDialog';
import { useAuth } from 'src/utils/useAuth'; // Import the useAuth hook
import 'src/styles/navbar.css';
import ChangeLanguage from 'src/pages/page_elements/ChangeLanguage';

function NavBar(): JSX.Element {
    const { t } = useTranslation();
    const { isLoggedIn, error, login, logout, resetError } = useAuth(); // Use the custom hook
    const [openLoginDialog, setOpenLoginDialog] = useState(false);
    const [openLogoutConfirmDialog, setOpenLogoutConfirmDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleOpenDialog = () => {
        setOpenLoginDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenLoginDialog(false);
    };

    const handleLogoutClick = () => {
        setOpenLogoutConfirmDialog(true);
    };

    const handleCloseLogoutConfirmDialog = () => {
        setOpenLogoutConfirmDialog(false);
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (!isLoggedIn) {
            handleMenuClose(); // Close the menu when isLoggedIn becomes false
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (!isLoggedIn) {
            handleCloseLogoutConfirmDialog(); // Close the dialog when isLoggedIn becomes false
        }
    }, [isLoggedIn]); // Add isLoggedIn as a dependency

    const handleLogoutSuccess = () => {
        logout(); // No need to close the dialog here, useEffect will handle it
        setOpenLogoutConfirmDialog(false);
    };

    useEffect(() => {
        if (isLoggedIn) {
            handleCloseDialog(); // Close the dialog when isLoggedIn becomes true
        }
    }, [isLoggedIn]); // Add isLoggedIn as a dependency

    const handleLoginSuccess = async (username: string, password: string) => {
        try {
            await login(username, password);
            // No need to close the dialog here, useEffect will handle it
        } catch (error) {
            // Handle login error if needed
        }
    };

    const menuId = 'primary-account-menu';

    return (
        <>
            <AppBar position="static" color="default" className="navbar">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="logo"
                        component={RouterLink}
                        to="/"
                        className="navbar-item"
                    >
                        <img
                            src="/ikona2_32.ico"
                            alt={t('elements.navbar.alts.logo')}
                        />
                    </IconButton>
                    <Button
                        color="inherit"
                        component={RouterLink}
                        to="/actions"
                        className="navbar-item"
                    >
                        {t('elements.navbar.actions')}
                    </Button>
                    <Button
                        color="inherit"
                        component={RouterLink}
                        to="/openairs"
                        className="navbar-item"
                    >
                        {t('elements.navbar.openairs')}
                    </Button>
                    <Button
                        color="inherit"
                        component={RouterLink}
                        to="/bands"
                        className="navbar-item"
                    >
                        {t('elements.navbar.bands')}
                    </Button>
{/*                     <IconButton
                        edge="end"
                        color="inherit"
                        component={RouterLink}
                        to="/settings"
                        className="navbar-item"
                        size="medium"
                    >
                        <SettingsIcon />
                    </IconButton> */}
                    <ChangeLanguage />
                    <div style={{ flexGrow: 1 }}></div>
                    {isLoggedIn ? (
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                            size="small"
                        >
                            <div className="user-icon navbar-item user-icon-container">
                                <AccountCircle fontSize="small"/>
                                <Typography variant="caption" className="user-icon-text">{t('elements.navbar.menu')}</Typography>
                            </div>
                        </IconButton>
                    ) : (
                        <IconButton aria-label="login" onClick={handleOpenDialog} className="user-icon navbar-item">
                            <div className="user-icon navbar-item user-icon-container">
                                <Login />
                                <Typography variant="caption" className="user-icon-text">{t('elements.navbar.login')}</Typography>
                            </div>
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
            <UserMenu
                anchorEl={anchorEl}
                isMenuOpen={isMenuOpen}
                handleMenuClose={handleMenuClose}
                handleLogoutClick={handleLogoutClick}
            />
            <LoginDialog
                open={openLoginDialog}
                onClose={handleCloseDialog}
                onLogin={handleLoginSuccess}
                error={error}
                resetError={resetError}
            />
            <LogoutConfirmDialog
                open={openLogoutConfirmDialog}
                onClose={handleCloseLogoutConfirmDialog}
                onConfirmLogout={handleLogoutSuccess}
            />
        </>
    );
}

export default NavBar;
